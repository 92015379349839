<template lang="pug">
#CompanySetting
    h3.d-flex.align-center.setting-title.px-4.py-3
        .cube-icon.small.bg-primary.mr-3
            v-icon(size='19' color='white') icon-companies-b
        span 公司設定
    .setting-inner
        p
            label 公司名稱
            v-text-field(v-model='company.name' type='text' solo dense disabled)
        p
            v-row.align-center(no-gutters)
                v-col(cols='11')
                    label 公司網域
                v-col(cols='1')
                    v-btn(v-if='company.domainDisabled' icon @click='company.domainDisabled = false' )
                        v-icon(size='20') icon-edit
                    v-btn(v-else icon @click='company.domainDisabled = true')
                        v-icon(size='20') icon-cancel
            v-row.align-center(no-gutters)
                v-col.pr-2(cols='12')
                    v-text-field(v-model='company.domain' :disabled='company.domainDisabled' type='text' solo dense)
                    span.mt-2.blue-grey--text.lighten-2--text.text-caption The company domain name is used for the Smart BCC address and your account's address on the web app.
                //- v-col.pl-2(cols='1')
                    v-btn(v-if='company.domainDisabled' icon @click='company.domainDisabled = false')
                        v-icon(size='20') icon-edit
                    v-btn(v-else icon @click='company.domainDisabled = true')
                        v-icon(size='20') icon-cancel
            //- label 公司網域
            //- v-row.align-center(no-gutters)
            //-     v-col.pr-2(cols='11')
            //-         v-text-field(v-model='company.domain' :disabled='company.domainDisabled' type='text' solo dense)
            //-         span.mt-2.blue-grey--text.lighten-2--text.text-caption The company domain name is used for the Smart BCC address and your account's address on the web app.
            //-     v-col.pl-2(cols='1')
            //-         v-btn(v-if='company.domainDisabled' icon @click='company.domainDisabled = false')
            //-             v-icon(size='20') icon-edit
            //-         v-btn(v-else icon @click='company.domainDisabled = true')
            //-             v-icon(size='20') icon-cancel
        v-row.mt-10.justify-center(no-gutters)
            v-col(cols='12' md='6')
                v-btn(:ripple='false' width='100%' height='45' color='success' depressed dark ) 儲存
</template>
<script>
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import loadingOverlay from "@/components/Common/loadingOverlay";

export default {
    name: "Login",
    components: {
        errorDialog,
        successDialog,
        loadingOverlay
    },
    data() {
        return {
            company: {
                // name: 'CM Square',
                // domain: 'cm2.io',
                // domainDisabled: true,
                name: '',
                domain: '',
                domainDisabled: true,
            },
        }
    },
    methods: {}
    };
</script>
