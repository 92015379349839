<template lang="pug">
    #AutoNumberSetting    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        h3.d-flex.align-center.setting-title.px-4.py-3
            .cube-icon.small.bg-primary.mr-3
                v-icon(size='18' color='white') fa-solid fa-hashtag
            span {{$t('SETTING.AUTONUMBERSETTING')}}
            v-row.text-right
                v-col(cols='12')
                    v-btn-toggle(v-model.lazy='autoNumberShoe' color='primary accent-3' exclusive group @change='changeType')
                        v-btn.ma-0(:value='true' height='40' :readonly='autoNumberShoe') {{$t('CASE.TITLE')}}
                        v-btn.ma-0(:value='false' height='40' :readonly='autoNumberShoe') {{$t('KNOWLEDGE_ARTICLE.TITLE')}}
        .setting-inner                
            v-col(cols='12' md='12' v-if='settingType=="case"')                    
                p
                    h3 {{$t('AUTONUMBERSETTING.CASE_SETTING')}}
                v-form(v-model="valid", ref="form")
                    p                
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                label {{$t('AUTONUMBERSETTING.PERFIX')}}
                                v-text-field.mb-3(v-model='caseData.prefix',solo dense,:rules='formRule.textRules',large='large')
                            v-col.pl-2(cols='6')
                                label {{$t('AUTONUMBERSETTING.SUFFIX')}}
                                v-text-field(v-model='caseData.suffix', solo dense :rules="formRule.textRules")
                    p
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.RECODR_TYPE')}}                    
                        v-select(v-model="caseData.record_type", solo dense :items="record_type_list",item-text="name",item-value="key")                        
                    p(v-if='caseIsNumber')
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.NUMBER_FORMAT')}}                    
                        v-select(v-model="caseData.number_type", solo dense :items="number_type_list",item-text="name",item-value="key")
                    p(v-if='caseIsNumber')
                        label {{$t('AUTONUMBERSETTING.START_NUMBER')}}
                        v-row(no-gutters)
                            v-col.pr-2
                                v-text-field(v-model='caseData.start_number', solo dense :rules="formRule.CaseNumberRules")
                    p(v-if='caseIsNumber')
                        label {{$t('AUTONUMBERSETTING.CURRENT_NUMBER')}}
                        v-row(no-gutters)
                            v-col.pr-2(cols='9' v-if='caseData.number_record != null')
                                v-text-field(v-model='caseData.number_record',readonly solo dense :rules="formRule.CaseNumberRules")
                            v-col.pr-2(cols='12' v-if='caseData.number_record == null')
                                v-text-field(v-model='noData' solo dense readonly)                            
                            v-col.ml-0(cols='1' v-if='caseData.number_record != null') 
                                v-btn(@click='resetNum("case")'  color='blue' depressed dark) {{$t('AUTONUMBERSETTING.RESET')}}
                    p
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.AUTO')}}                    
                        v-select(v-model="caseData.autofill_title", solo dense :items="active_list",item-text="name",item-value="key")
                    p
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.ACTIVATED')}}                    
                        v-select(v-model="caseData.active", solo dense :items="active_list",item-text="name",item-value="key")
                    p
                    v-row.mt-10.justify-center(no-gutters)
                        v-col(cols='12' md='6')
                            v-btn(:ripple='false' width='100%' height='45' color='success' depressed dark @click='onSave("case",caseData)') 儲存


            v-col(cols='12' md='12' v-if='settingType=="KA"')                    
                p
                    h3 {{$t('AUTONUMBERSETTING.KA_SETTING')}} 
                v-form(v-model="valid", ref="form")
                    p                
                        v-row(no-gutters)
                            v-col.pr-2(cols='6')
                                label {{$t('AUTONUMBERSETTING.PERFIX')}} 
                                v-text-field.mb-3(v-model='KAData.prefix',solo dense,:rules='formRule.textRules',large='large')
                            v-col.pl-2(cols='6')
                                label {{$t('AUTONUMBERSETTING.SUFFIX')}} 
                                v-text-field(v-model='KAData.suffix', solo dense :rules="formRule.textRules")
                    p
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.RECODR_TYPE')}}                     
                        v-select(v-model="KAData.record_type", solo dense :items="record_type_list",item-text="name",item-value="key")                        
                    p(v-if='KAIsNumber')
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.NUMBER_FORMAT')}}                     
                        v-select(v-model="KAData.number_type", solo dense :items="number_type_list",item-text="name",item-value="key")
                    p(v-if='KAIsNumber')
                        label {{$t('AUTONUMBERSETTING.START_NUMBER')}} 
                        v-row(no-gutters)
                            v-col.pr-2
                                v-text-field(v-model='KAData.start_number', solo dense :rules="KAData.number_type == 'FIVE_DIGITS'? formRule.CaseNumberRules:formRule.KANumberRules")
                    p(v-if='KAIsNumber')
                        label {{$t('AUTONUMBERSETTING.CURRENT_NUMBER')}} 
                        v-row(no-gutters)
                            v-col.pr-2(cols='9' v-if='KAData.number_record != null')
                                v-text-field(v-model='KAData.number_record', readonly solo dense :rules="KAData.number_type == 'FIVE_DIGITS'? formRule.CaseNumberRules:formRule.KANumberRules")
                            v-col.pr-2(cols='12' v-if='KAData.number_record == null')
                                v-text-field(v-model='noData' solo dense readonly)
                            v-col.ml-0(cols='1' v-if='KAData.number_record != null') 
                                v-btn(@click='resetNum("KA")' color='blue' depressed dark) {{$t('AUTONUMBERSETTING.RESET')}} 
                    //- p
                    //-     label(for='personalMail') {{$t('AUTONUMBERSETTING.AUTO')}}                    
                    //-     v-select(v-model="caseData.autofill_title", solo dense :items="active_list",item-text="name",item-value="key")
                    p
                        label(for='personalMail') {{$t('AUTONUMBERSETTING.ACTIVATED')}}                     
                        v-select(v-model="KAData.active", solo dense :items="active_list",item-text="name",item-value="key")
                    p                    
                    v-row.mt-10.justify-center(no-gutters)
                        v-col(cols='12' md='6')
                            v-btn(:ripple='false' width='100%' height='45' color='success' depressed dark @click='onSave("KA",KAData)') 儲存           
                                
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
                message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
                success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
                error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import messageDialog from '@/components/Dialog/messageDialog';    
    import AutoNumberService from "@/services/AutoNumberService";
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import loadingOverlay from "@/components/Common/loadingOverlay"; 
    import numberTypeList from "@/array/the_numberTypeList";    
    import i18n from '/common/plugins/vue-i18n.js' 

	export default Vue.extend({
        props: {  
            
        },
        components: {   
                loadingOverlay,             
                messageDialog,
                successDialog,
                errorDialog,
            },        
        data() {
		return {            
            caseData:{},
            KAData:{},
            record_type_list:[{key:"TIMESTAMP",name:i18n.t('AUTONUMBERSETTING.TIME_MODE')},{key:"SEQUENCE_NUMBER",name:i18n.t('AUTONUMBERSETTING.NUMBER_MODE')}],
            number_type_list:numberTypeList,
            active_list:[{key:true,name:i18n.t('AUTONUMBERSETTING.ACTIVATED')},{key:false,name:i18n.t('AUTONUMBERSETTING.UNACTIVATED')}],
            autoNumberShoe: true,
            settingType:null,
            caseIsNumber:null,
            KAIsNumber:null,
            noData:i18n.t('AUTONUMBERSETTING.NO_RECORD'),
            editData:{},
            editType:"",
            unchange:false,
            loading:false,            
            errorMessage:"",
            message:"",
            messageDialog:false,
            errorDialog:false,
            successDialog:false,
            valid:false,            
            formRule: {            
            textRules:[
                (v) => /^.{0,10}$/.test(v) || i18n.t("RULE.RULE_10"),
                // (v) => /^[a-zA-Z\-0-9]*$|(^$)|null/.test(v) || "請填寫英文或數字"
            ],
            CaseNumberRules: [
                (v) => !!v || i18n.t("RULE.RULE_R"),
                (v) => /^.{0,5}$/.test(v) || i18n.t("RULE.RULE_5"),
                (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t("RULE.RULE_NUM")
            ],
            KANumberRules: [
                (v) => !!v || i18n.t("RULE.RULE_R"),
                (v) => /^.{0,10}$/.test(v) || i18n.t("RULE.RULE_10"),
                (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t("RULE.RULE_NUM")
                ],
            },
        }},
        created()
        {            
            AutoNumberService.list().then(response => 
            {
                this.caseData = response.data.filter(el => {return el.using_page == "CASE"})[0];
                this.KAData = response.data.filter(el => {return el.using_page == "KNOWLEDGE_ARTICLE"})[0];
                this.settingType="case";
                if(this.caseData.prefix ==null)  
                {
                    this.caseData.prefix = "";
                }
                if(this.KAData.prefix ==null)  
                {
                    this.KAData.prefix = "";
                } 
                if(this.caseData.suffix ==null)  
                {
                    this.caseData.suffix = "";
                } 
                if(this.KAData.suffix ==null)  
                {
                    this.KAData.suffix = "";
                }            
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
        },
        methods:{
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)
                    return true;
                else
                    return false;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                if(val)
                {
                    this.editData.number_record = this.editData.start_number ;
                    this.onSave(this.editType,this.editData);
                }else
                {
                    this.unchange = true;
                    this.onSave(this.editType,this.editData);
                }
                this.messageDialog = false; 
            }, 
            onSave(name,data)
            {
                this.editData = data;
                this.editType = name;
                if(this.validateForm() && data.record_type=="SEQUENCE_NUMBER" && !this.unchange &&  data.number_record != null)
                {
                    if(data.start_number > data.number_record)
                    {                        
                        this.showMessageDialog(i18n.t('AUTONUMBERSETTING.MESSAGE'));
                        return;
                    }
                }
                if(this.validateForm() ){
                    
                   AutoNumberService.edit(data).then((response)=>
                   {
                       if(name=="case")
                       {
                           this.caseData = response.data;
                       }
                       if(name=="KA")
                       {
                           this.KAData = response.data;
                       }
                       this.showSuccessDialog();
                       this.editData = {};
                       this.editType = "";
                       this.unchange = false;
                   })
                }
        
            },
            changeType(val) {
            if (val) {
                this.settingType="case";
            } else if(val!=undefined) {
                this.settingType="KA";
            }
            },  
            resetNum(type) {
               if (type == "case") {
                this.caseData.number_record = null;
            } else if(type == "KA") {
                this.KAData.number_record = null;
            }
            },    
        },
        watch:{
            "caseData.record_type": async function(){
                if(this.caseData.record_type == "SEQUENCE_NUMBER")
                {
                    this.caseIsNumber = true;
                }else
                {
                    this.caseIsNumber = false;
                }                
            },
            "caseData.number_type": async function(){
                const x = this.caseData.number_type;
                let text = `\^.{0,${x}}$`; //eslint-disable-line     
                var regex = new RegExp(text);
                this.formRule.CaseNumberRules = [
                (v) => !!v || i18n.t('RULE.RULE_R'),
                (v) => regex.test(v) || i18n.t('AUTONUMBERSETTING.RULE')+`${x}`+i18n.t('AUTONUMBERSETTING.RULE_1'),
                (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')
                ]           
            },
            "KAData.record_type": async function(){
                if(this.KAData.record_type == "SEQUENCE_NUMBER")
                {
                    this.KAIsNumber = true;
                }else
                {
                    this.KAIsNumber = false;
                }
            },
            "KAData.number_type": async function(){                
                const x = this.KAData.number_type
                let text = `\^.{0,${x}}$`; //eslint-disable-line               
                var regex = new RegExp(text);  
                this.formRule.KANumberRules = [
                (v) => !!v || i18n.t('RULE.RULE_R'),
                (v) => regex.test(v) || i18n.t('AUTONUMBERSETTING.RULE')+`${x}`+i18n.t('AUTONUMBERSETTING.RULE_1'),
                (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM')
                ]           
            },
            // "caseData.start_number": async function(){
            //     if(this.caseData.number_record == null)
            //     {
            //         this.caseData.number_record = this.caseData.start_number
            //     }else
            //     {
            //         this.KAIsNumber = false
            //     }
            // }
        }
	});
</script>

<style lang="css">    
    /* .autonumber-inner {
        max-width: 1000px;
        padding: 40px 20px;
        margin: 0 auto;
        height: calc(100% - 61px);
    } */
</style>