<template lang="pug">
    #AssistantSettingAndUpload    
        loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
        v-row.setting-title.align-center.py-3.px-4(no-gutters)
            v-col(cols="11", md="11")
                h3.d-flex.align-center.mb-0
                    .cube-icon.small.mr-3.openaicubeicon
                        v-icon(class='openailogo')
                    span {{$t('SETTING.ASSISTANTSETTINGANDUPLOAD')}}
            v-col(cols="1").d-flex.justify-end
                v-btn.ml-2(@click="reFreshUpGPT" height="40" color="green" depressed dark)
                    v-icon.mr-1(size="14") mdi-refresh
                    span {{$t('GENERAL.REFRESH')}}
        .setting-GPTinnerpage
            //--基礎設定--                 
            v-col(cols='12' md='12')                   
                v-row(no-gutters)
                    v-col(cols='12' md='9')
                        h3 {{$t('UPGPT.BASICSETTING')}}
                    v-col(cols='12' md='3')
                        v-btn(:ripple='false' width='100%' height='45' depressed class='upchatinboxbtn' @click='turntoupchatinbox()') {{$t('UPGPT.UPCHAT_INBOX_SETTING')}}
                v-form(v-model="valid", ref="baseSettingForm")
                    p
                        v-row.mt-2(no-gutters)
                            v-col.pr-5(cols='6')
                                span {{$t('UPGPT.AIASSISTANTNAME')}}
                                v-tooltip(right)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-icon(class='circle_fill_information' v-bind='attrs' v-on='on')
                                    p {{$t('UPGPT.AIASSISTANTNAME_TIP1')}}
                                    p {{$t('UPGPT.AIASSISTANTNAME_TIP2')}}
                                v-text-field(v-model='BaseSettingData.assistant_name', solo dense :rules="formRule.AINameRules")
                            v-col(cols='6')
                                span {{$t('UPGPT.AIASSISTANTROLE')}}
                                v-tooltip(bottom)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-icon(class='circle_fill_information' v-bind='attrs' v-on='on')
                                    p {{$t('UPGPT.AIASSISTANTROLE_TIP1')}}
                                    p {{$t('UPGPT.AIASSISTANTROLE_TIP2')}}
                                v-text-field(v-model='BaseSettingData.assistant_role', solo dense :rules="formRule.AIRoleRules")
                    p
                        v-row.mt-2(no-gutters)
                            v-col.pr-5(cols='6')
                                span {{$t('UPGPT.TOPIC')}}
                                v-tooltip(right)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-icon(class='circle_fill_information' v-bind='attrs' v-on='on')
                                    p {{$t('UPGPT.TOPIC_TIP1')}}
                                    p {{$t('UPGPT.TOPIC_TIP2')}}
                                v-text-field(v-model='BaseSettingData.assistant_topic', solo dense :rules="formRule.TopicRules")
                            v-col(cols='6')
                                span {{$t('UPGPT.TOPICLIMIT')}}
                                v-tooltip(right)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-icon(class='circle_fill_information' v-bind='attrs' v-on='on')
                                    p {{$t('UPGPT.TOPICLIMIT_TIP1')}}
                                    p {{$t('UPGPT.TOPICLIMIT_TIP2')}}
                                v-text-field(v-model='BaseSettingData.assistant_topic_limit', solo dense :rules="formRule.TopicLimitRules")
                    p
                        v-row.mt-2(no-gutters)
                            v-col.pr-5(cols='6')
                                span {{$t('UPGPT.REPLY_LANGUAGE')}}
                                v-tooltip(right)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-icon(class='circle_fill_information' v-bind='attrs' v-on='on')
                                    span {{$t('UPGPT.REPLY_LANGUAGE_TIP')}}
                                v-select(v-model='BaseSettingData.assistant_language' :items='assistant_languageList' item-value='key' item-text='name' solo dense :rules="formRule.LanguageRules")
                    P
                    v-row.mt-10.justify-end(no-gutters)
                        v-col(cols='12' md='3')
                            v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='basesettingchangeing' :loading='basesettingchangeing' depressed :dark='!basesettingchangeing' @click='onSave(BaseSettingData)') {{$t('UPGPT.BASICSETTINGSAVE')}}
                        //- v-col(cols='12' md='3')
                        //-     v-btn(:ripple='false' width='100%' height='45' depressed class='upchatinboxbtn' @click='turntoupchatinbox()') {{$t('UPGPT.UPCHAT_INBOX_SETTING')}}
            hr
            //--AI助理知識管理--
            v-col(cols='12' md='12').pt-0
                v-row(no-gutters)
                    v-col(cols='12' md='9')
                        h3(style='padding-top:0.35em;') {{$t('UPGPT.AIASSISTANT_KNOWLEDGE_MANAGEMENT')}}
                    //--重置按鈕--
                    v-col(cols='12' md='3')
                        v-btn(v-if='!isAnalysising' :ripple='false' width='100%' height='45' color='blue' :disabled='reseting' :loading='reseting' depressed :dark='!reseting' @click='resetData()') {{$t('UPGPT.RESETDATA')}} 
                //--簡要資訊--
                v-row.mb-5(v-if='noDATA' no-gutters)
                    v-col(cols='12' md='12')
                        h6(style='padding-top:0.35em;') {{$t('UPGPT.NOFILE')}}
                v-row.mb-5(v-else no-gutters)
                    v-col(cols='12' md='12')
                        h6(:v-model='total_file_amount' style='padding-top:0.35em;') {{$t('UPGPT.FILE_AMOUNT') + total_file_amount}}
                        h6(:v-model='updated_time' style='padding-top:0.35em;') {{$t('UPGPT.UPDATE_TIME') + updated_time}}
                v-card(flat)
                    v-tabs(v-model='tab' background-color='transparent' grow show-arrows id='gptupload')
                        v-tabs-slider
                        v-tab(v-for='item in tabs' :key='item.key' :href='`#tab-${item.id}`'  style='display:flex; justify-content:center !important;')
                            | {{ item.name }}
                        //--已上傳檔案--
                        v-tab-item.pt-5(key='Uploaded' value='tab-1' :transition='false' :reverse-transition='false' style='min-height:0px;')                     
                            v-data-table.mt-10.dashboard-table(
                                :item-class="rowClass"
                                :headers="FileTableheaders",
                                :items="filename_arr",
                                :items-per-page="FilesPerPage",
                                :page.sync="FileTablePage",
                                @page-count="FilePageCount = $event",
                                hide-default-footer="hide-default-footer",
                            )
                                template( v-slot:item.name="{ item }" )
                                    .ellipsis(:title='item.name' height='36px')
                                    div(class='d-flex justify-left' :title='item.name')
                                        span(style='margin-left:50px;') {{item.name}}
                                template( v-slot:item.actions="{ item }" )
                                    v-btn(height="40" color="primary" depressed dark @click="downloadtxt(item.name)" :disabled='txtDownloading' :dark='!txtDownloading')
                                        span {{$t('UPGPT.DOWNLOAD')}}
                            v-pagination.mt-5(v-model='FileTablePage' :length='FilePageCount' circle :total-visible="10")
                        //--Q&A資料上傳--
                        v-tab-item.pt-5(key='QA' value='tab-2' :transition='false' :reverse-transition='false' style='min-height:0px;')                     
                            v-form(v-model="valid", ref="form")
                                p               
                                    v-row(no-gutters)
                                        v-col(cols='12' id="QAinput")
                                            v-file-input.pa-0( prepend-icon="fa-solid fa-file-csv" accept=".csv" :placeholder= '$t("UPGPT.QAFILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.QAfileRule" v-model='QAAttachments' @change='onChangeQAFile')
                                p                                          
                                v-row.mt-10.justify-end(no-gutters)
                                    v-col(cols='12' md='3')
                                        v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='QAuploading || isAnalysising' depressed @click='onUpload("QA")') {{QAuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.QAUPLOAD')}}
                                            v-icon(size='16' color='black' :disabled='true' v-if='QAuploading || isAnalysising') fas fa-circle-notch fa-spin

                        //--資料夾上傳--
                        v-tab-item.pt-5(key='Folder' value='tab-3' :transition='false' :reverse-transition='false' style='min-height:0px;')                   
                            v-form(v-model="valid", ref="form")
                                p               
                                    v-row(no-gutters)
                                        v-col(cols='12' id="Folderinput")
                                            v-file-input.pa-0( prepend-icon="mdi-folder-zip" accept=".zip" :placeholder= '$t("UPGPT.FOLDERUPLOADMESSAGE")' truncate-length="20" :rules="formRule.FolderfileRule" v-model='FolderAttachments' @change='onChangeFolderFile')
                                p                                          
                                v-row.mt-10.justify-end(no-gutters)
                                    v-col(cols='12' md='3')
                                        v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='Folderuploading || isAnalysising' depressed @click='onUpload("Folder")') {{Folderuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.FOLDERUPLOAD')}}
                                            v-icon(size='16' color='black' :disabled='true' v-if='Folderuploading || isAnalysising') fas fa-circle-notch fa-spin
                        //--檔案上傳--
                        v-tab-item.pt-5(key='File' value='tab-4' :transition='false' :reverse-transition='false' style='min-height:0px;')                   
                            v-form(v-model="valid", ref="form")
                                p               
                                    v-row(no-gutters)
                                        v-col(cols='12' id="Fileinput")
                                            v-file-input.pa-0( prepend-icon="mdi-file-document-plus" accept=".txt,.pdf,.docx,.pptx" :placeholder= '$t("UPGPT.FILEUPLOADMESSAGE")' truncate-length="20" :rules="formRule.FilefileRule" v-model='FileAttachments' @change='onChangeFile')
                                p                                          
                                v-row.mt-10.justify-end(no-gutters)
                                    v-col(cols='12' md='3')
                                        v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='Fileuploading || isAnalysising' depressed @click='onUpload("File")') {{Fileuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.FILEUPLOAD')}}
                                            v-icon(size='16' color='black' :disabled='true' v-if='Fileuploading || isAnalysising') fas fa-circle-notch fa-spin
                        //--網站分析--
                        v-tab-item.pt-5(key='Website' value='tab-5' :transition='false' :reverse-transition='false' style='min-height:0px;')
                            v-row.mb-5.justify-end(no-gutters)
                                v-col(cols='12' md='3')
                                    v-btn(:ripple='false' width='100%' height='45' color='success' depressed @click='showAddWebsite') 
                                        v-icon.mr-1(size="14") icon-add
                                        span {{$t('UPGPT.ADD_URL')}} 
                            v-data-table.dashboard-table(
                                :item-class="rowClass"
                                :headers="Websiteheaders",
                                :items="Object.values(WebsitesSettingData)",
                                :items-per-page="WebsitesPerPage",
                                :page.sync="WebsiteTablePage",
                                @page-count="WebsitePageCount = $event",
                                hide-default-footer="hide-default-footer",
                            )
                                template( v-slot:item.url="{ item }")
                                    .ellipsis(:title='item.url' height='36px')
                                    div(class='d-flex justify-left' :title='item.url')
                                        span(style='margin-left:50px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;') {{item.url}} 
                                template( v-slot:item.level="{ item }")
                                    span {{item.level}}   
                                template(v-slot:item.actions="{ item }")
                                    v-btn(icon, @click="showEditWebsite(item)")
                                        v-icon(size="18" ) icon-edit          
                                    v-btn(icon, @click="onDelete(item.id)")
                                        v-icon(size="18") icon-remove
                            v-row.mt-10.justify-end(no-gutters)
                                v-col(cols='12' md='3')
                                    v-btn(:ripple='false' width='100%' height='45' color='success' :disabled='Webuploading || isAnalysising'  depressed  @click='web_analysis') {{Webuploading||isAnalysising ? $t('UPLOADSTATUS.EMBEDDING') : $t('UPGPT.START_ANALYSIS')}}
                                        v-icon(size='16' color='black' :disabled='true' v-if='Webuploading || isAnalysising') fas fa-circle-notch fa-spin
                            v-row.mt-10.justify-end(no-gutters)
                                v-col(cols='12' md='3')
                                    v-btn(v-if="hasWebFile" :ripple='false' width='100%' height='45' color='primary' :disabled='zipDownloading' :loading='zipDownloading' depressed :dark='!zipDownloading' @click='downloadwebzip') {{$t('UPGPT.DOWNLOAD_ZIP')}}
        
        v-dialog( v-model="websiteAddDialog", max-width="500", content-class="setting-dialog", v-if="websiteAddDialog === true")
            v-card
                v-card-title.d-flex.justify-center {{$t('UPGPT.ADD_URL')}}
                v-card-text
                    v-form(v-model="valid", ref="form")
                        v-text-field.no-border.v-text-field__slot(
                            v-model="addWebsitesSettingData.url",
                            type="text",
                            :label=`$t('UPGPT.URL')`,
                            prepend-icon="mdi-web",
                            :rules="formRule.URLRules"
                        )
                        v-text-field.no-border.v-text-field__slot(
                            v-model="addWebsitesSettingData.level",
                            type="number",
                            :label=`$t('UPGPT.LEVEL')`,
                            prepend-icon="mdi-digital-ocean",
                            :rules="formRule.LevelRules"
                        )          
                    
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onCancel",
                                    width="100%",
                                    height="40",
                                    color="cancel",
                                    depressed,
                                    dark
                                ) {{$t('GENERAL.CANCEL')}}    
                            v-col.pl-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onConfirmAddWeb()",
                                    width="100%",
                                    height="40",
                                    color="success",
                                    depressed,
                                    :disabled='addWebsiteloading',
                                    :loading='addWebsiteloading',
                                    :dark='!addWebsiteloading',
                                ) {{$t('GENERAL.ADD')}}
        
        v-dialog( v-model="websiteEditDialog", max-width="500", content-class="setting-dialog", v-if="websiteEditDialog === true")
            v-card
                v-card-title.d-flex.justify-center {{$t('UPGPT.EDIT_URL')}}
                v-card-text
                    v-form(v-model="valid", ref="form")
                        v-text-field.no-border.v-text-field__slot(
                            v-model="editWebsitesSettingData.url",
                            type="text",
                            :label=`$t('UPGPT.URL')`,
                            prepend-icon="mdi-web",
                            :rules="formRule.URLRules"
                        )
                        v-text-field.no-border.v-text-field__slot(
                            v-model="editWebsitesSettingData.level",
                            type="number",
                            :label=`$t('UPGPT.LEVEL')`,
                            prepend-icon="mdi-digital-ocean",
                            :rules="formRule.LevelRules"
                        )          
                    
                    v-card-actions.mt-8.px-0.py-0
                        v-row(no-gutters)
                            v-col.pr-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onCancel",
                                    width="100%",
                                    height="40",
                                    color="cancel",
                                    depressed,
                                    dark
                                ) {{$t('GENERAL.CANCEL')}}    
                            v-col.pl-2(cols="6")
                                v-btn(
                                    :ripple="false",
                                    @click="onConfirmEditWeb()",
                                    width="100%",
                                    height="40",
                                    color="success",
                                    depressed,
                                    :disabled='editWebsiteloading',
                                    :loading='editWebsiteloading',
                                    :dark='!editWebsiteloading',
                                ) {{$t('GENERAL.EDIT')}}

        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>
<script>
	import Vue from 'vue';
    import loadingOverlay from "@/components/Common/loadingOverlay"; 
    import successDialog from "@/components/Dialog/successDialog";
    import errorDialog from "@/components/Dialog/errorDialog";
    import messageDialog from '@/components/Dialog/messageDialog';    
    import i18n from '/common/plugins/vue-i18n.js'    
    import UpGPTService from "@/services/UpGPTDataService";
    import PreferenceService from "@/services/PreferenceService";
    import UploadStatusDataService from "@/services/UploadStatusDataService";
    import JsZip from "jszip";

	export default Vue.extend({
        props: {  
            
        },
        components: {   
            loadingOverlay,             
            successDialog,
            errorDialog,
            messageDialog,
        },        
        data() {
		return {            
            loading:false,
            valid:false,            
            BaseSettingData:{},
            formRule: {            
                AINameRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,20}$/.test(v) || i18n.t("RULE.RULE_20"),
                ],
                AIRoleRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                TopicRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                TopicLimitRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                LanguageRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                ],
                QAfileRule: [
                    () => { return (this.QAfileSize || i18n.t('RULE.RULE_FILE_3'))},
                ],
                FolderfileRule: [
                    () => { return (this.FolderSize || i18n.t('RULE.RULE_FILE_3'))},
                ],
                FilefileRule: [
                    () => { return (this.FolderSize || i18n.t('RULE.RULE_FILE_3'))},
                ],
                URLRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+*.~#?&//=]*)$|(^$)|null/.test(v) || i18n.t("RULE.RULE_URL"),
                    (v) => /^.{0,200}$/.test(v) || i18n.t("RULE.RULE_200"),
                ],
                LevelRules:[
                    (v) => !!v || i18n.t('RULE.RULE_R'),
                    (v) => /^([1-2])$/.test(v) || i18n.t("RULE.RULE_R_NUM_6"),
                ],
            },
            editData:{},
            QAAttachments:null,
            FolderAttachments:null,
            FileAttachments:null,
            QAfileSize:true,
            FolderSize:true,
            FileSize:true,
            successDialog:false,
            errorDialog:false,
            errorMessage:"",
            messageDialog:false,
            message:"",
            messagetype:"",
            basesettingchangeing:false,
            QAuploading:false,
            Folderuploading:false,
            Fileuploading:false,
            reseting:false,
            tab: null,
            tabs: [
                {
                    id: 1,
                    key: 'Uploaded',
                    name: i18n.t('UPGPT.FILE_TABLE')
                },
                {
                    id: 2,
                    key: 'QA',
                    name: i18n.t('UPGPT.QAUPLOAD')
                },
                {
                    id: 3,
                    key: 'Folder',
                    name: i18n.t('UPGPT.FOLDERUPLOAD')
                },
                {
                    id: 4,
                    key: 'File',
                    name: i18n.t('UPGPT.FILEUPLOAD')
                },
                {
                    id: 5,
                    key: 'Website',
                    name: i18n.t('UPGPT.WEBSITEANALYSIS')
                },
            ],
            total_file_amount: 0,
            updated_time: '',
            filename_arr: [],
            noDATA: false,
            FileTablePage: 1,
            FilePageCount: 0,
            FilesPerPage: 8,
            FileTableheaders: [
                {
                    text: i18n.t('UPGPT.FILE'),
                    align: 'center',
                    sortable: false,
                    value: 'name',
                    class: "blue lighten-5",
                },
                { 
                    text: i18n.t('UPGPT.DOWNLOAD_TXT'), 
                    align: 'center',
                    sortable: false,
                    value: 'actions', 
                    class: "blue lighten-5",
                    width: '20%' ,
                },
            ],
            txtDownloading: false,
            WebsitesSettingData: {},
            websiteAddDialog: false,
            addWebsiteloading: false,
            addWebsitesSettingData: {
                url:'',
                level:1,
            },
            websiteEditDialog: false,
            editWebsiteloading: false,
            editWebsitesSettingData: {
                url:'',
                level:'',
            },
            Websiteheaders: [
                {
                    text: i18n.t('UPGPT.URL'),
                    align: 'center',
                    sortable: false,
                    value: 'url',
                    class: "blue lighten-5",
                    width: '60%' ,
                },
                {
                    text: i18n.t('UPGPT.LEVEL'),
                    align: 'center',
                    sortable: false,
                    value: 'level',
                    class: "blue lighten-5",
                    width: '20%' ,
                },
                { 
                    text: i18n.t('UPGPT.ACTION'), 
                    align: 'center',
                    sortable: false,
                    value: 'actions', 
                    class: "blue lighten-5",
                    width: '20%' ,
                },
            ],
            WebsiteTablePage: 1,
            WebsitePageCount: 0,
            WebsitesPerPage: 10,
            Websiteid: '',
            Webuploading: false,
            zipDownloading: false,
            hasWebFile:false,
            isAnalysising:false,
            assistant_languageList: [
                {key:'auto',name:i18n.t('UPGPT.AUTO_DETECT')},
                {key:'Traditional Chinese',name:'繁體中文'},
                {key:'English',name:'English'},
                {key:'Vietnamese',name:'Tiếng Việt'},
                {key:'Burmese',name:'မြန်မာအက္ခရာ'},
                {key:'Laotian',name:'ພະຍັນຊະນະລາວ'},
                {key:'Turkish',name:'Türkçe'},
                {key:'Thai',name:'ไทย'},
                {key:'Telugu',name:'తెలుగు'},
                {key:'Albanian',name:'Shqip'},
                {key:'Slovenian',name:'Slovenščina'},
                {key:'Slovak',name:'Slovenčina'},
                {key:'Russian',name:'Русский'},
                {key:'Romanian',name:'Română'},
                {key:'Brazilian Portuguese',name:'Português do Brasil'},
                {key:'Portuguese',name:'Português'},
                {key:'Dutch',name:'Nederlands'},
                {key:'Mongolian',name:'Mongolian'},
                {key:'Korean',name:'한국어'},
                {key:'Georgian',name:'ქართული'},
                {key:'Japanese',name:'日本語'},
                {key:'Italian',name:'Italiano'},
                {key:'Indonesian',name:'Bahasa Indonesia'},
                {key:'Hungarian',name:'Magyar'},
                {key:'Hebrew',name:'עברית '},
                {key:'French',name:'Français'},
                {key:'Finnish',name:'suomi'},
                {key:'Spanish',name:'Español'},
                {key:'German',name:'Deutsch'},
                {key:'Danish',name:'Dansk'},
                {key:'Czech',name:'Čeština'},
                {key:'Catalan',name:'Catalan'},
                {key:'Bulgarian',name:'Български'},
                {key:'Arabic',name:'العربية'},
                {key:'Simplified Chinese',name:'简体中文'},
                {key:'Polish',name:'Polski'},
                {key:'Swedish',name:'Svenska'},
            ],
        }},
        created()
        {       
            UpGPTService.getSettingData().then(response => 
            {
                this.BaseSettingData = {
                    "assistant_name" : response.data.assistant_name,
                    "assistant_role" : response.data.assistant_role,
                    "assistant_topic" : response.data.assistant_topic,
                    "assistant_topic_limit" : response.data.assistant_topic_limit,
                    "assistant_language" : response.data.assistant_language,
                };
                if(response.data.temp_zip_json){
                    this.hasWebFile = true;
                }
                else
                    this.hasWebFile = false;
            })
            .catch((response) => {
                this.showErrorDialog(response);
            });
            this.getKnowledgeInfo();
            this.getWebsitesInfo();
            this.isAnalysising = this.analysis_check();
        },
        methods:{
            reFreshUpGPT(){
                UpGPTService.getSettingData().then(response => 
                {
                    this.BaseSettingData = {
                        "assistant_name" : response.data.assistant_name,
                        "assistant_role" : response.data.assistant_role,
                        "assistant_topic" : response.data.assistant_topic,
                        "assistant_topic_limit" : response.data.assistant_topic_limit,
                        "assistant_language" : response.data.assistant_language,
                    };
                    if(response.data.temp_zip_json){
                        this.hasWebFile = true;
                    }
                    else
                        this.hasWebFile = false;
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
                this.getKnowledgeInfo();
                this.getWebsitesInfo();
            },
            validateForm(){
                this.valid = this.$refs.form.validate();
                if(this.valid)
                    return true;
                else
                    return false;
            },
            onSave(data)
            {
                if(this.$refs.baseSettingForm.validate()){
                    this.basesettingchangeing = true;
                    this.editData = data;
                    UpGPTService.setSettingData(data).then((response)=>
                    {
                        this.basesettingchangeing = false;
                        if(response.data == "wrong preference"){
                            this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                        } else {
                            this.reFreshUpGPT()
                            this.showSuccessDialog();
                        }
                        this.editData = {};
                    })
                }
            },
            rowClass() {
                const rowClass = 'dataTableColor'
                return rowClass;
            }, 
            resetData() {
                this.showMessageDialog('reset',i18n.t('UPGPT.DELETEALLDATA'));
            },
            onChangeQAFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.QAfileSize = false;
                    } else {
                        this.QAfileSize = true;
                    }
                } else {
                    this.QAfileSize = false;
                }
            },
            onChangeFolderFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.FolderSize = false;
                    } else {
                        this.FolderSize = true;
                    }
                } else {
                    this.FolderSize = false;
                }
            },
            onChangeFile(event) {
                if (event != null) {
                    // file size
                    if (event.size > 20971520) {
                        this.FileSize = false;
                    } else {
                        this.FileSize = true;
                    }
                } else {
                    this.FileSize = false;
                }
            },
            async onUpload(name){
                if(name == "QA"){
                    if(this.validateForm() && this.QAfileSize && this.QAAttachments != null){
                        this.loading = true;
                        this.QAuploading = true;
                        await UpGPTService.uploadQAfile(this.QAAttachments).then((response)=>{
                            if(response.data == "wrong type"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINCSV'));
                            } 
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else{
                                this.loading = false;
                                this.QAuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.QAAttachments = null;
                        });
                    }
                } else if(name == "Folder"){
                    if(this.validateForm() && this.FolderSize && this.FolderAttachments != null){
                        this.loading = true;
                        this.Folderuploading = true;
                        await UpGPTService.uploadFolder(this.FolderAttachments).then((response)=>{
                            if(response.data == "wrong type"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINZIP'));
                            }
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.QAuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else{
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.Folderuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.FolderAttachments = null;
                        });
                    }
                } else if(name == "File"){
                    if(this.validateForm() && this.FileSize && this.FileAttachments != null){
                        this.loading = true;
                        this.Fileuploading = true;
                        await UpGPTService.uploadFile(this.FileAttachments).then((response)=>{
                            if(response.data == "wrong type"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.WRONGTYPEINFILE'));
                            }
                            else if(response.data == "wrong preference"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                            }
                            else if(response.data == "wrong embedding"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                            }
                            else if(response.data == "fail"){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                            }
                            else{
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showSuccessDialog();
                                this.getKnowledgeInfo();
                            }
                        }).catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                            }
                            else {
                                this.loading = false;
                                this.Fileuploading = false;
                                this.showErrorDialog(String(response));
                                this.getKnowledgeInfo();
                            }
                        }).finally(()=>{
                            this.FileAttachments = null;
                        });
                    }
                }
            },
            getKnowledgeInfo() {
                UpGPTService.getKnowledgeInfo().then(response => 
                {
                    this.total_file_amount = response.data.total_file_amount;
                    if(response.data.updated_time == null){
                        this.noDATA = true;
                    }
                    else{
                        this.updated_time = response.data.updated_time;
                        this.noDATA = false;
                    }
                    this.filename_arr = response.data.filename_arr;
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            turntoupchatinbox() {
                PreferenceService.getUpChat().then(response => 
                {
                    let host = response.data.host;
                    let account_id = response.data.account_id;
                    window.open(`${host}/app/accounts/${account_id}/settings/inboxes/list`);
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            downloadtxt(filename) {
                this.txtDownloading = true;
                let dotdetect_arr =  filename.split('.');
                let replace_str = '.' + dotdetect_arr[dotdetect_arr.length-1]
                let downloadfileName = filename.replace(replace_str,'.txt');
                UpGPTService.downloadtxt(filename).then(response => 
                {
                    if(response.data == "deleted"){
                        this.txtDownloading = false;
                        this.showErrorDialog(i18n.t('UPGPT.FILE_HAS_BEEN_DELETED'));
                    } else {
                        let a = document.createElement('a');
                        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.data);
                        a.download = downloadfileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.txtDownloading = false;
                        this.showSuccessDialog();
                    }
                })
                .catch((response) => {
                    this.txtDownloading = false;
                    this.showErrorDialog(response);
                });
            },
            showAddWebsite() {
                this.websiteAddDialog = true;
            },
            getWebsitesInfo() {
                UpGPTService.getWebsitesSettingData().then(response => 
                {
                    this.WebsitesSettingData = response.data;       
                })
                .catch((response) => {
                    this.showErrorDialog(response);
                });
            },
            onCancel(){
                this.websiteAddDialog = false;
                this.websiteEditDialog = false;
                this.addWebsitesSettingData.url = '';
                this.addWebsitesSettingData.level = 1;
                this.editWebsitesSettingData.url = '';
                this.editWebsitesSettingData.level = '';
            },
            onConfirmAddWeb(){
                if(Object.keys(this.WebsitesSettingData).length < 5){
                    this.addWebsiteloading = true;
                    if(this.validateForm()){    
                        UpGPTService.setWebsitesSettingData(this.addWebsitesSettingData).then((response)=>
                        {
                            this.addWebsiteloading = false;
                            if(response.data == 'overlimit'){
                                this.showErrorDialog(i18n.t('UPGPT.OVERLIMIT'));
                            } else {
                                this.showSuccessDialog();
                            }
                        }).catch((response) => {
                            this.addWebsiteloading = false;
                            this.showErrorDialog(response);
                        }).finally(()=>{
                            this.getWebsitesInfo();
                            this.addWebsitesSettingData.url = '';
                            this.addWebsitesSettingData.level = 1;
                            this.websiteAddDialog = false;
                        });
                    } else {
                        this.addWebsiteloading = false;
                    }
                } else {
                    this.showErrorDialog(i18n.t('UPGPT.OVERLIMIT'));
                }
            },
            showEditWebsite (item) {
                this.websiteEditDialog = true;
                this.Websiteid = item.id;
                this.editWebsitesSettingData.url = item.url;
                this.editWebsitesSettingData.level = item.level;
            },
            onConfirmEditWeb(){
                this.editWebsiteloading = true;
                if(this.validateForm()){    
                    UpGPTService.updateWebsitesSettingData(this.Websiteid,this.editWebsitesSettingData).then(()=>
                    {
                        this.editWebsiteloading = false;
                        this.showSuccessDialog();
                    }).catch((response) => {
                        this.addWebsiteloading = false;
                        this.showErrorDialog(response);
                    }).finally(()=>{
                        this.getWebsitesInfo();
                        this.editWebsitesSettingData.url = '';
                        this.editWebsitesSettingData.level = '';
                        this.websiteEditDialog = false;
                        this.Websiteid = '';
                    });
                } else {
                    this.editWebsiteloading = false;
                }
            },
            async web_analysis(){
                this.loading = true;
                this.Webuploading = true;
                await UpGPTService.websiteanalysis().then((response)=>{
                    if(response.data == "wrong preference"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.PREFERENCE_WRONG'));
                    }
                    else if(response.data == "wrong embedding"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.EMBEDDING_WRONG'));
                    }
                    else if(response.data == "fail"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.UPLOAD_FAIL'));
                    }
                    else if(response.data == "no url"){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showErrorDialog(i18n.t('UPGPT.NO_URL'));
                    }
                    else{
                        this.loading = false;
                        this.Webuploading = false;
                        this.hasWebFile = true;
                        this.showSuccessDialog();
                        this.reFreshUpGPT();
                    }
                }).catch((response) => {
                    if(String(response).indexOf('timeout') >= 0){
                        this.loading = false;
                        this.Webuploading = false;
                        this.showMessageDialog("processing",i18n.t('UPGPT.TIMEOUT_ERROR'));
                    }
                })
            },
            onDelete(id) {
                this.Websiteid = id;
                this.showMessageDialog("delWebsite",i18n.t('UPGPT.DELETE_CHECK'));
            },
            downloadwebzip() {
                this.zipDownloading = true;
                UpGPTService.downloadwebzip().then(response => 
                {
                    if(response.data == "blank"){
                        this.zipDownloading = false;
                        this.showErrorDialog(i18n.t('UPGPT.BLANK_WEB_INFO'));
                    } else {
                        // 初始化zip打包物件
                        let zip = new JsZip();
                        // 逐筆建立要打包的檔案
                        for(let fileinfo of response.data){
                            zip.file(fileinfo.filename, fileinfo.content);
                        }
                        // 把打包內容非同步轉成blob二進位制格式
                        zip.generateAsync({type:"blob"}).then(function(content) {
                            // 下載zip    
                            // saveAs(content, "website_analysis_data.zip");
                            let a = document.createElement('a');
                            a.href = URL.createObjectURL(content);
                            a.download = 'website_analysis_data.zip';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        });
                        this.zipDownloading = false;
                        this.showSuccessDialog();
                    }
                })
                .catch((response) => {
                    this.zipDownloading = false;
                    this.showErrorDialog(response);
                });
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
             },
            showErrorDialog(message) {  
                this.errorDialog = true;      
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            showMessageDialog(type,message) {
                this.messageDialog = true;
                this.messagetype = type
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if(this.messagetype == "processing"){
                    if(val){
                        this.$emit("emitChangeTab","UploadStatus")
                    }
                }
                else if(this.messagetype == "reset"){
                    if(val){
                        this.loading = true;
                        this.reseting = true;
                        UpGPTService.resetAllKnowledge()
                        .then((response)=>{
                            if(response.data == 'Succeeded'){
                                this.loading = false;
                                this.reseting = false;
                                this.showSuccessDialog();
                                this.reFreshUpGPT();
                            } else {
                                this.loading = false;
                                this.reseting = false;
                                this.showErrorDialog(i18n.t('UPGPT.NOT_CLEAN'));
                                this.reFreshUpGPT();
                            }
                        })
                        .catch((response) => {
                            if(String(response).indexOf('timeout') >= 0){
                                this.loading = false;
                                this.reseting = false;
                                this.showSuccessDialog();
                            } else {
                                this.loading = false;
                                this.reseting = false;
                                this.showErrorDialog(response);
                            }
                            this.reFreshUpGPT();
                        });
                    }
                }
                else if(this.messagetype == "delWebsite"){
                    if(val){
                        UpGPTService.deleteWebsitesSettingData(this.Websiteid).then(()=>
                        {
                            this.showSuccessDialog();
                        }).catch((response) => {
                            this.showErrorDialog(response);
                        }).finally(()=>{
                            this.getWebsitesInfo();
                        });
                        this.Websiteid = '';
                    }
                }   
                this.messagetype = '';   
            }, 
            async analysis_check(){
                await UploadStatusDataService.analysis_check().then((response)=>{
                    this.isAnalysising = response.data;
                });
            },
        },
        watch:{

        }
	});
</script>

<style lang="css"> 
  #QAinput .v-input .v-input__control .v-text-field__details  .v-messages .v-messages__wrapper .v-messages__message{
    margin: 5px 0 0 7%;
  } 

  #Folderinput .v-input .v-input__control .v-text-field__details  .v-messages .v-messages__wrapper .v-messages__message{
    margin: 5px 0 0 7%;
  }

  #Fileinput .v-input .v-input__control .v-text-field__details  .v-messages .v-messages__wrapper .v-messages__message{
    margin: 5px 0 0 7%;
  }

  .setting-GPTinnerpage{
    max-width: 1000px;
    padding: 10px 20px;
    margin: 0 auto;
    height: calc(100% - 61px);
  }
  .openaicubeicon{
    background-color: white;
  }
  .openailogo{
    height: 30px;
    width: 30px;
    content: url('https://api.iconify.design/logos/openai-icon.svg');
  }
  .circle_fill_information{
    height: 25px;
    width: 25px;
    margin: 0 0 1% 2%;
    content: url('https://api.iconify.design/mdi/information-outline.svg?color=%23007bff');
  }
  #gptupload .v-window{
    border-radius: 6px !important;
  } 
  .upchatinboxbtn{
    color:#00aa60 !important;
    font-weight: 600 !important;
    background-color: white !important;
    border: 2px solid #00aa60 !important;
  }
  .dataTableColor:hover {
    background-color: rgba(180, 240, 255, 0.164) !important;
  }
</style>