<template lang="pug">
#Setting-CalendarSync(v-if='!isLoading')
    v-row.setting-title.align-center.px-4.py-3(no-gutters)
        v-col(cols='12' sm='9' xl='10')
            h3.d-flex.align-center.mb-0
                .cube-icon.small.bg-primary.mr-3
                    v-icon(size='19' color='white') icon-mail
                span {{$t('SETTING.CALENDARSYNC')}}
        v-col.text-md-right(cols='12' sm='3' xl='2')
            v-btn(v-if='o365AccountData.length != 0 && !syncData.enable_calendar_sync' @click='startSync' width='100%' height='40' color='green' depressed dark)
                v-icon.mr-1(size='20' v-if='!isSyncing') mdi-flip-h mdi-sync
                span {{ isSyncing ? $t('EMAILSYNC.SYNCING'):$t('EMAILSYNC.START_SYNC')}}
                v-progress-circular(size='18' v-if='isSyncing' indeterminate color='white')
            v-btn(v-if='o365AccountData.length != 0 && syncData.enable_calendar_sync' @click='stopSync' width='100%' height='40' color='red' depressed dark)
                v-icon.mr-1(size='20' ) mdi-flip-h mdi-sync
                span {{ $t('CALENDAR_SYNC_SETTINGS.STOP_SYNC')}}
            v-btn(v-if='o365AccountData.length == 0' @click='showAddCalendar' width='100%' height='40' color='green' depressed dark)
                v-icon.mr-1(size='14') icon-add
                span {{$t('EMAILSYNC.ADD_ACCOUNT')}}
    .setting-inner(v-if='notEmailSetting')
        h4 {{$t('EMAILSYNC.TEXT')}}
        p {{$t('EMAILSYNC.TEXT_2')}}
        v-row.sheet-box.my-5(v-if='o365AccountData.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('EMAILSYNC.NO_CONNECT')}}
        v-list-item(v-else v-for='item in o365AccountData' :key='item.id')
            v-list-item-content
                v-list-item-title.d-flex.justify-space-between.align-center
                    span.f-text-highlight.text-subtitle-2.mb-0 {{ item.o365_user_principal_name }}
                    span.div 
                        v-btn(icon='icon' @click='onDeleteO365Account(item.id)')
                            v-icon(size='18') icon-remove
    .setting-inner(v-if='o365AccountData.length > 0').pt-0
        v-row
            v-col(cols='4').pa-0
                h5.pt-2(v-if='o365AccountData.length != 0') {{$t('CALENDAR_SYNC_SETTINGS.SELECT_CALENDAR')}} :
            v-col(cols='8').pa-0.pl-3
                v-select.no-border(
                    v-model="syncData.calendar_id",
                    :items="calendarList",
                    item-text="name",
                    item-value="id",
                    flat solo dense
                    @change='updateSetting()'
                    :class='syncData.enable_calendar_sync ? "remove-arrow" : ""'
                    :readonly='syncData.enable_calendar_sync'
                )
                    template(v-slot:selection='{ item }')
                        .t-black.pl-3 {{ item.name }}
        v-row.pt-2
            v-col(cols='4').pa-0
                h5.pt-2 {{$t('CALENDAR_SYNC_SETTINGS.SYNC_TYPE')}} :
            v-col(cols='8').pa-0.pl-3.sync_type_card.selected
                v-card.elevation-0.mb-1.d-flex.justify-center.align-start(:class="syncData.sync_type==0? 'selected':''" @click="change_sync_way(0)")
                    v-row
                        v-col(cols="1").pt-1.d-flex.justify-center.align-start
                            v-card-text
                                v-icon(v-if='syncData.sync_type==1' class='v-icon notranslate far fa-square theme--light')
                                v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                        v-col(cols="11").pt-0.pl-0
                            v-card-title.pb-0
                                span {{$t("CALENDAR_SYNC_SETTINGS.SYNC_TYPE_ONE_0")}}
                            v-card-text.pt-0.pb-0
                                span ．{{$t("CALENDAR_SYNC_SETTINGS.SYNC_TYPE_ONE_1")}}
                            //- v-card-text.pt-0
                            //-     span ．{{$t("CALENDAR_SYNC_SETTINGS.SYNC_TYPE_ONE_2")}}
                v-card.elevation-0.d-flex.justify-center.align-start(:class="syncData.sync_type==1? 'selected':''" @click="change_sync_way(1)")
                    v-row
                        v-col(cols="1").pt-1.d-flex.justify-center.align-start
                            v-card-text
                                v-icon(v-if='syncData.sync_type==0' class='v-icon notranslate far fa-square theme--light')
                                v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                        v-col(cols="11").pt-0.pl-0
                            v-card-title.pb-0
                                span {{$t("CALENDAR_SYNC_SETTINGS.SYNC_TYPE_TWO_0")}}
                            v-card-text.pt-0.pb-0
                                span ．{{$t("CALENDAR_SYNC_SETTINGS.SYNC_TYPE_TWO_1")}}
                            v-card-text.pt-0
                                span ．{{$t("CALENDAR_SYNC_SETTINGS.SYNC_TYPE_TWO_2")}}
        v-row.pt-2(v-if='syncData.sync_type==1')
            v-col(cols='4').pa-0
                h5.pt-2 {{$t('CALENDAR_SYNC_SETTINGS.SYNC_AS')}} :
            v-col(cols='8').pa-0.pl-3
                v-select.placeholder-black.no-border(
                    v-model="syncData.sync_as_activity_type",
                    :items="activityTypeList",
                    item-text="name",
                    item-value="id",
                    flat solo dense
                    @change='updateSetting()'
                )
                    template(v-slot:selection='{ item }')
                        .t-black.pl-3 {{ item.name }}
                    template(v-slot:item='{ item }')
                        v-list-item-group(class='inline-edit-cell')
                            v-list-item-avatar.d-inline-block(:color='item.color', size="25")
                                v-icon(size="18" color='white') {{ item.icon }}
                        .t-black {{ item.name }}
        v-row.pt-2(v-if='syncData.sync_type==1')
            v-col(cols='4').pa-0
                h5.pt-2 {{$t('CALENDAR_SYNC_SETTINGS.PRIVATE_EVENTS')}} :
            v-col(cols='8').pa-0
                v-checkbox.ma-0.pa-0.pt-1(:readonly='private_events_arr[0]',:input-value='private_events_arr[0]' :label=`$t('CALENDAR_SYNC_SETTINGS.PRIVATE_EVENTS_0')` @click='syncPrivate(0)') 
                v-checkbox.ma-0.pa-0(:readonly='private_events_arr[1]',:input-value='private_events_arr[1]' :label=`$t('CALENDAR_SYNC_SETTINGS.PRIVATE_EVENTS_1')` @click='syncPrivate(1)')
        v-row.pt-2
            v-col(cols='4').pa-0
                h5.pt-2 {{$t('CALENDAR_SYNC_SETTINGS.SELECT_TO_SYNC')}} :
            v-col(cols='8').pa-0
                v-combobox.placeholder-black.pl-3(v-model='syncData.activity_types_to_sync' :items='activityTypeList' item-value='id' :placeholder="syncData.activity_types_to_sync.length == 0? $t('CALENDAR_SYNC_SETTINGS.NO_SELECT_TYPE'): ''" item-text='name' height='36' @change='change_sync_types()' flat dense solo multiple :return-object='false')
                    template(v-slot:selection='{ item }')
                        .t-black {{ activityTypeList.filter(el=>el.id==item)[0].name }},
                    template(v-slot:item='{ item }')
                        v-icon(v-if='!syncData.activity_types_to_sync.includes(item.id)' class='v-icon notranslate far fa-square theme--light')
                        v-icon(v-else class='v-icon notranslate fas fa-check-square theme--light primary--text')
                        v-list-item-group.ml-3(class='inline-edit-cell')
                            v-list-item-avatar.d-inline-block(:color='item.color', size="25")
                                v-icon(size="18" color='white') {{ item.icon }}
                        .t-black {{ item.name }}
        v-row.pt-2
            v-col(cols='4').pa-0
                h5.pt-2(v-if='o365AccountData.length != 0') {{$t('CALENDAR_SYNC_SETTINGS.SYNC_REGARDING')}} :
            v-col(cols='8').pa-0
                v-checkbox.ma-0.pa-0.pt-1(:readonly='sync_regarding_arr[0]',:input-value='sync_regarding_arr[0]' :label=`$t('CALENDAR_SYNC_SETTINGS.SYNC_REGARDING_0')` @click='syncRegarding(0)') 
                v-checkbox.ma-0.pa-0(:readonly='sync_regarding_arr[1]',:input-value='sync_regarding_arr[1]' :label=`$t('CALENDAR_SYNC_SETTINGS.SYNC_REGARDING_1')` @click='syncRegarding(1)')

    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='connectCalendarAccountDialog' max-width='500' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 {{$t('EMAILSYNC.DIALOG')}}
            v-card-text
                v-form(v-model='connectCalendar.valid' ref='form')
                    v-row(no-gutters)
                        v-col.pr-2(cols='12')
                            p {{$t('EMAILSYNC.DIALOG_2')}}
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='6')
                            v-btn(:ripple='false' @click='connectCalendarAccountDialog = false' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                        v-col.pl-2(cols='6')
                            v-btn(:ripple='false' @click='onCalendarAccountAdd' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
    v-dialog(v-model='confirmCalendarAccountDialog' max-width='500' content-class='setting-dialog')
        v-card
            v-card-title.d-flex.justify-center.mb-4 {{$t('EMAILSYNC.DIALOG_S')}}
            v-card-text
                v-form(v-model='connectCalendar.valid' ref='form')
                    v-row(no-gutters)
                        v-col.pr-2(cols='12')
                            p {{$t('CALENDAR_SYNC_SETTINGS.CONNECT_SUCCESS')}}
                v-card-actions.mt-8.px-0.py-0
                    v-row(no-gutters)
                        v-col.pr-2(cols='12')
                            v-btn(:ripple='false' @click='closeConnectCalendarAccount' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CLOSE')}}
</template>

<script>
    import Vue from 'vue';
    import O365Service from "../../services/O365Service";
    import ActivityDataService from "@/services/ActivityDataService";
    import errorDialog from '@/components/Dialog/errorDialog';
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from "@/components/Dialog/successDialog";
    import i18n from '/common/plugins/vue-i18n.js'  

    export default Vue.extend({
        components: {
            errorDialog,
            messageDialog,
            successDialog,
        },
        props: {},
        data() {
            return {
                private_events_arr:[false,false],
                sync_regarding_arr:[false,false],
                successDialog: false,
                notEmailSetting: true,
                confirmCalendarAccountDialog: this.$route.query.confirmO365CalendarSetup == 'Y',
                connectCalendarAccountDialog: false,
                connectCalendar: {
                    emailAddress: '',
                },
                o365AccountData: [],
                messageDialog: false,
                message: '',
                syncData:null,
                isSyncing:false,
                canSync:false,
                errorDialog:false,
                errorMessage:'',
                activityTypeList:[],
                isLoading:false,
                syncTypesList:[{key:0,name:"One-Way"},{key:1,name:"Two-Way"}],
                calendarList:[],
            }
        },
        async created(){
            this.isLoading = true;
            const USER = 'user';
            let userJson = window.localStorage.getItem(USER);
            let user = JSON.parse(userJson);
            let userID = user.userid;
            await O365Service.getO365CalendarAccounts()
            .then(response => {
                this.o365AccountData = (response.data);
            });

            await O365Service.getCalendarSetting(userID)
            .then((response)=>{
                this.syncData = response.data.setting;
                this.calendarList = response.data.calendarList;
                this.set_sync_setting();
            })
            await ActivityDataService.getActivityType()
            .then(response => {
                this.activityTypeList = response.data;
                this.activityTypeList.forEach((el)=> el.id=parseInt(el.id))
            })
            .catch(response => {
                console.log(response);
            })
            await(this.isLoading = false);
        },        
        methods: {
            startSync(){
                if(!this.isSyncing){
                    this.isSyncing = true;
                    O365Service.syncCalendar()
                    .then(()=>{
                        this.isSyncing = false;
                        this.syncData.enable_calendar_sync = true;
                    })
                }
            },
            stopSync(){
                O365Service.stopCalendar().then(()=>{
                    this.syncData.enable_calendar_sync = false;
                })
            },
            syncCheck(val){
                if(val == this.syncData.enable_email_sync){
                    return;
                }
                if(val){
                    this.syncData.enable_email_sync =true;
                }else{
                    this.syncData.enable_email_sync =false;
                }
                this.updateSetting();
            },
            syncPrivate(val){
                if(this.private_events_arr[val] == true){
                    return;
                }
                switch(val){
                    case 0:
                        this.syncData.sync_private_events = 0;
                        break;
                    case 1:
                        this.syncData.sync_private_events = 1;
                        break;
                    default:
                        break;
                }
                this.updateSetting();
            },
            syncRegarding(val){
                if(this.sync_regarding_arr[val] == true){
                    return;
                }
                switch(val){
                    case 0:
                        this.syncData.sync_regarding = false;
                        break;
                    case 1:
                        this.syncData.sync_regarding = true;
                        break;
                    default:
                        break;
                }
                this.updateSetting();
            },
            set_sync_setting(){
                switch(this.syncData.sync_private_events){
                    case 0:
                        this.private_events_arr = [true,false];
                        break;
                    case 1:
                        this.private_events_arr = [false,true];
                        break;
                    default:
                        break;
                }
                switch(this.syncData.sync_regarding){
                    case false:
                        this.sync_regarding_arr = [true,false];
                        break;
                    case true:
                        this.sync_regarding_arr = [false,true];
                        break;
                    default:
                        break;
                }
            },
            change_sync_types(){
                this.updateSetting();
            },
            change_sync_way(val){
                this.syncData.sync_type=val;
                this.updateSetting();
            },
            updateSetting(){
                O365Service.editCalendarSetting(this.syncData)
                .then((response)=>{
                    this.syncData = response.data;
                    this.set_sync_setting();
                })
            },
            onDeleteO365Account() {
                this.messageDialog = true;
                this.message = i18n.t('EMAILSYNC.MESSAGE');
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    this.confirmDeleteO365Account();
                }
            },
            showAddCalendar() {
                this.connectCalendarAccountDialog = true;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            confirmDeleteO365Account() {
                this.loading = true;
                let id = this.o365AccountData[0].id;
                O365Service.deleteO365CalendarAccounts(id)
                .then(() => {
                    this.loading = false;
                    this.showSuccessDialog();
                    var index = this.o365AccountData.map(x => { return x.Id; }).indexOf(id);
                    this.o365AccountData.splice(index, 1);
                })
                .catch(() => {
                    this.loading = false;
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('EMAILSYNC.ERROR');
                });
            },
            onCalendarAccountAdd() {
                O365Service.addO365CalendarAccount()
                .then(response => {
                    var url = response.data;
                    window.location.href = url;
                    setTimeout(()=>{
                        this.confirmCalendarAccountDialog = true;
                    },2000)
                });
            },
            closeConnectCalendarAccount(){
                this.confirmCalendarAccountDialog = false;
                window.location.search='';
            },
        }
    });
</script>

<style lang="scss">
    .v-label.theme--light {
        color: rgba(0, 0, 0) !important;
    }
    .sync_type_card{
        .v-card{
            width: 100%;
            height: 90px;
            border: 1px black solid;
            border-radius: 0px !important;
        }
        .selected{
            background-color: rgba(236, 237, 243, 0.8);
        }
    }
</style>