<template lang="pug">
#CustomFields
  v-row.setting-title.align-center.py-3.px-4(no-gutters)
    v-col(cols="11", md="11")
      h3.d-flex.align-center.mb-0
        .cube-icon.small.bg-primary.mr-3
          v-icon(size="19", color="white") icon-folder
        span {{$t('ACTIVITY.TYPE_SETTING')}}
    v-col(cols="1").d-flex.justify-end
          v-btn(@click="showCSAdd" height="40" color="green" depressed dark)
            v-icon.mr-1(size="14") icon-add
            span {{$t('ACTIVITY.ADD_TYPE_BTN')}}
  .px-4.py-3 
    section
      v-data-table.dashboard-table(
        item-key="editId",
        :item-class="rowClass"
        :headers="typeFieldsHeader",
        :header-props="{sortIcon: 'mdi mdi-arrow-up'}",
        :items="activityTypeList",
        :items-per-page="itemPerPage",
        :page.sync="page",
        @page-count="pageCount = $event",
        multi-sort
        :search="searchMangeUser",
        fixed-header,
        hide-default-footer="hide-default-footer",
        dense
        height='715' 
      )
        template( v-slot:item.name="{ item }" )
            v-text-field.v-text-field__slot(id="TypeName" ref="form" v-model="item.name" hide-details="true"  dense single-line :autofocus="true" :rules="formRule.fieldRules" v-if="item.editId === editedItem.editId")
            .ellipsis(v-else :title='item.type_name')
                span {{item.name}}
        template( v-slot:item.color="{ item }" )
            div(class='d-flex justify-left position-relative')
                .inline-edit-cell
                    v-list-item-avatar.d-inline-block.ma-0.mx-4(v-if="item.editId != editedItem.editId" :color="item.color", size="25")
                div
                  v-dialog(v-if="item.editId === editedItem.editId" v-model="selectColor" attach max-width="300px" overlay-opacity='0' content-class="elevation-5 select-color-dialog" transition="slide-x-transition")
                    template(v-slot:activator="{ on, attrs }")
                      v-list-item-avatar.d-inline-block.ma-0.mx-4(class='select-color-btn' v-if="item.editId === editedItem.editId" :color="item.color", size="25" v-on="on" v-bind="attrs")
                    v-color-picker(v-model="item.color" )
        template( v-slot:item.icon="{ item }")
            v-row(class='d-flex justify-center')
              select-icon-dialog(v-if="item.editId === editedItem.editId" @emitSelectIconDialog='onEmitSelectIconDialog' :currentIcon='item.icon' :isAdd='false')
              v-icon(v-else size="25" :color="item.color") {{item.icon}}
        template(v-slot:item.active="{ item }")
          v-btn(icon, @click="close()" v-if="item.editId === editedItem.editId")            
            v-icon(size="18" color="red") mdi-window-close
          v-btn(icon, @click="editItem(item)" v-if="item.editId != editedItem.editId && isEditable===true")
            v-icon(size="18" ) icon-edit  
          v-btn(icon, @click="save($event,'TYPE')" v-if="item.editId === editedItem.editId")
            v-icon(size="18" color="green") fas fa-check         
          v-btn(icon, @click="onDelete($event,item)" v-if="item.editId != editedItem.editId && item.id < 100000")
            v-icon(size="18") icon-remove
      v-pagination.mt-3(v-model='page' :length='pageCount' circle :total-visible="10" v-if='!loading' style='position: absolute; bottom: 0; width: 100%; height: 50px')
    v-dialog(
      v-model="ATAddDialog",
      max-width="450",
      content-class="setting-dialog",
      v-if="ATAddDialog === true"
    )
      v-card
        v-card-title.d-flex.justify-center {{$t('ACTIVITY.ADD_TYPE')}} 
        v-card-text
          v-form(v-model="addTypeData.valid", ref="form")
            v-text-field.v-text-field__slot(
              v-model="addTypeData.name",
              type="text",
              :label=`$t('ACTIVITY.TYPE_NAME')`,
              :rules="formRule.fieldRules"
            )
            span {{$t('ACTIVITY.TYPE_COLOR')}}
            v-color-picker.ml-12(v-model="addTypeData.color")
            select-icon-dialog( @emitSelectIconDialog='onEmitSelectIconDialog' :currentIcon='addTypeData.icon' :isAdd='true')
          v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
              v-col.pr-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCancel",
                  width="100%",
                  height="40",
                  color="cancel",
                  depressed,
                  dark
                ) {{$t('GENERAL.CANCEL')}}
              v-col.pl-2(cols="6")
                v-btn(
                  :ripple="false",
                  @click="onCSAdd",
                  width="100%",
                  height="40",
                  color="success",
                  depressed,
                  dark
                ) {{$t('GENERAL.ADD')}}
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import ActivityDataService from "@/services/ActivityDataService";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import selectIconDialog from "@/components/Dialog/Activity/selectIcon";
import draggable from 'vuedraggable';
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    draggable,
    messageDialog,
    successDialog,
    errorDialog,
    selectIconDialog,
  },
  data() {
    return {
      selectColor:false,
      selectIconDialog:true,
      typeFieldsHeader: [
        { text: i18n.t('ACTIVITY.TYPE_NAME'), value: "name", align: "left" , width: '35%' ,class: "blue lighten-5", sortable: false, },
        { text: i18n.t('ACTIVITY.TYPE_COLOR'), value: "color", align: "left", width: '25%' ,class: "blue lighten-5", sortable: false, },
        { text: i18n.t('ACTIVITY.TYPE_ICON'), value: "icon", align: "center", width: '25%' ,class: "blue lighten-5", sortable: false},
        { text: i18n.t('CUSTOMERSERVICESETTING.ACTIVE'), value: "active", align: "center", width: '15%' ,class: "blue lighten-5", sortable: false},
      ],
      activityTypeList:[],
      isEditable:true, 
      formRule: {
        fieldRules: [
          (v) => !!v || i18n.t('RULE.RULE_R'),
          (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')
          ],
      },
      editedIndex:null,
      stageTypeList:[
        {key: "Type", name: i18n.t('CUSTOMERSERVICESETTING.TYPE')},
        {key: "Group", name: i18n.t('CUSTOMERSERVICESETTING.GROUP')},
      ],
      newData:{},
      addTypeData: {
        name: "",
        color: "",
        icon: '',
      },
      editedItem: {},  
      ATAddDialog: false,
      messageDialog: false,
      message: '',
      successDialog: false,
      errorDialog: false,
      errorMessage: '',      
      searchMangeUser: "",
      page: 1,
      pageCount: 1,
      itemPerPage: 12,
      loading:false,
    };
  },
  async created() {
    this.loading = true;
    await ActivityDataService.getActivityType().then((response)=>{
      this.activityTypeList = response.data;
      this.processType();
    });
  },
  methods: {
    onEmitSelectIconDialog(data,isAdd){
      if(isAdd){
        this.addTypeData.icon = data;
      }else{
        this.editedItem.icon = data;
      }
      
    },
    rowClass() {
        const rowClass = 'dataTableColor'
        return rowClass;
    },
    processType() {
        for (let i in this.activityTypeList) {
          if(!this.activityTypeList[i].editId)
          {
            Vue.set(this.activityTypeList[i], "editId", "TYPE-"+this.activityTypeList[i].id);
          }
        }
        this.loading = false;
    },
    showMessageDialog(message) {
        this.messageDialog = true;
        this.message = message;
    },
    // 執行刪除
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if(val){
        ActivityDataService.deleteActivityType(this.delData.id).then((response)=>{
          if(response.data.error == "Is using!"){
              this.showErrorDialog(i18n.t('ACTIVITYTYPES.ERROR'));
              return;
          }
          if(response.data.error == "Can't be empty!"){
              this.showErrorDialog(i18n.t('ACTIVITYTYPES.ERROR_2'));
              return;
          }
          if(response.data.error == "default"){
              this.showErrorDialog(i18n.t('ACTIVITYTYPES.ERROR_3'));
              return;
          }
          this.editedIndex = this.activityTypeList.indexOf(this.delData);
          const delIndex =  JSON.parse(JSON.stringify(this.editedIndex));
          this.activityTypeList.splice(delIndex, 1);
          this.delData = {};
          this.dleEvent = null;
          this.editedIndex = null;
          this.emptyData = false;
        })
      }
    },
    showSuccessDialog() {
        this.successDialog = true;
    },
    onEmitSuccessDialog() {
        this.successDialog = false;
    },
    showErrorDialog(message) {  
        this.errorDialog = true;      
        this.errorMessage = message;
    },
    onEmitErrorDialog() {
        this.errorDialog = false;
    },
    // 儲存編輯
    save() {
      if (this.editedIndex > -1 && this.validateForm()) {    
          delete this.editedItem.editId
          ActivityDataService.editActivityType(this.editedItem).then(()=>{
            this.processType()
          });
        this.close("save")
      }
    },
    // 關閉編輯
    close (val) {
      if(val != "save"){
        this.activityTypeList = this.defaultItem
      }
      this.isEditable = true
      this.editedItem = {}
      this.editedIndex = -1;
    },
    // 打開編輯
    editItem (item) {
      this.editedIndex = this.activityTypeList.indexOf(item);
      this.defaultItem = JSON.parse(JSON.stringify(this.activityTypeList));
      this.isEditable = false     
      this.editedItem = item     
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    // 刪除確認
    onDelete(event,item) {
      this.mode = "delItem";
      this.delData = item;
      this.dleEvent = event;
      if(this.activityTypeList.length == 1){
        this.showErrorDialog(i18n.t('ACTIVITYTYPES.ERROR_2'));
        return;
      }
      this.showMessageDialog(i18n.t('CUSTOMERSERVICESETTING.MESSAGE'));
    },
    // 顯示ADD Dialog
    showCSAdd() {
      this.ATAddDialog = true;
      this.addTypeData.icon = 'mdi-bookmark';
      this.addTypeData.color = "#9E9E9E";
      this.cancelData = JSON.parse(JSON.stringify(this.addTypeData));
    },   
    // 取消編輯
    onCancel() {
      this.ATAddDialog = false;
      this.addTypeData = this.cancelData;
    },
    // 新增種類
    onCSAdd() {
      if (this.validateForm()) {   
        this.newData = {}
          this.newData.name = this.addTypeData.name;
          this.newData.icon = this.addTypeData.icon;
          this.newData.color = this.addTypeData.color;
          ActivityDataService.addActivityType(this.newData).then((response)=>
          {
            this.activityTypeList.unshift(response.data);
            this.processType();
          });
        this.ATAddDialog = false;
        this.addTypeData = this.cancelData;
      }
    },
  },

});
</script>
<style lang="css">
.nopadding {
  padding-left: 0;
}
.dataTableColor:hover {
  background-color: rgba(180, 240, 255, 0.164) !important;
}
</style>